import React, { useContext, useEffect, useState } from "react";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { getRequestConfigWithUserToken, handleUserSignUp, sendPostRequest, toastError, toastSuccess } from "../../util/util";
import axios from "axios";
import { navigate } from "gatsby";

const UserSignUp = () => {
    const [userSignUpHandled, setUserSignUpHandled] = useState(false);
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        let tempName = "Username";
        const params = new URLSearchParams(window.location.search);
        const name = params.get("name");
        if (name !== null) {
            tempName = name;
        }
        if (!userSignUpHandled && user) {
            handleUserSignUp(user, tempName);
            setUserSignUpHandled(true);
            navigate('/user');
        }
    }, [user, userSignUpHandled]);
    
    return (
        <div className="tw-flex tw-justify-center tw-items-center">
                <span className="visually-hidden">Creating your account ...</span>
        </div>
    )
}

export default UserSignUp;